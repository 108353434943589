<template>
    <CSDialog dialogTitle="发卡" dialogWidth="596px" :dialog-confirm-btn-text="confirmText" :dialogVisible="visible"
              @onConfirm="writeCard" @onClose="onClose" :dialog-show-confirm-btn-disabled="isSendingCard" @click="closeFrom"
    >
        <template v-slot:dialog-content>
            <div class="access-card-dialog-content">
                <div class="step-wrap">
                    <div :class="['active', {finished: stepCount === 0}]">1</div>
                    <div :class="['step-bar']" :style="{'--bar-width': `${stepCount === 0?50:100}%`}"></div>
                    <div :class="['active', {finished: stepCount === 1}]">2</div>
                    <div class="step-bar" :style="{'--bar-width': `${stepCount / 2  * 100}%`}"></div>
                    <div :class="{active: stepCount === 2}">3</div>
                </div>
                <div class="write-tip" v-html="writeTip"></div>
                <template v-if="stepCount === 0">
                    <div class="form-item">
                        <span class="form-item-label">选择使用范围</span>
                        <div class="form-item-content"
                             style="&#45;&#45;height: 40px;font-size: 24px;&#45;&#45;cs-select-icon: 36px;">
                            <ThreePanelSeletFrom @onClick="pickDeviceGroup" textName="name"
                                                 @refresh="refreshDeviceGroup"
                                                 :panelGroupPanelList="panelGroupPanelList" @loadMore="loadDeviceGroup"
                                                 @selectAll='selectAll' :deviceIds="deviceIds" @panelChecked="panelChecked"
                            />
                        </div>
                    </div>
                    <div class="form-item">
                        <span class="form-item-label">是否允许被复制</span>
                        <div class="form-item-content"
                             style="&#45;&#45;height: 40px;font-size: 24px;&#45;&#45;cs-select-icon: 36px;">
                            <CSRadio
                                    v-model="entranceRestrict"
                                    :items="{ true: '允许', false: '不允许' }"
                            />
                        </div>
                    </div>
                </template>
            </div>
        </template>
    </CSDialog>
</template>

<script>
    import CSDialog from "@/components/common/CSDialog";
    import ReaderFunction from "@/utils/ReaderFunction";
    import CSSelect from "@/components/common/CSSelect";
    import TreePanel from "@/components/common/TreePanel";
    import TreePanelSelect from "@/components/common/TreePanelSelect";
    import ThreePanelSeletFrom from './ThreePanelSeletFrom'
    import CSRadio from "@/components/common/CSRadio";
    import {
        createAccessCardDataUrl,
        createAccessStaffCardDataUrl,
        queryGroup,
        staffSendCardUrl,
        userSendCardUrl,
        queryDeviceAllUrl
    } from "@/requestUrl";


    const CARD_DEVICE_COLOR = {
        NULL: 0,
        RED: 1,
        GREEN: 2,
    }

    export default {
        name: "AccessCardWrite",
        components: {
            TreePanelSelect,
            TreePanel,
            CSSelect,
            CSDialog,
            ThreePanelSeletFrom,
            CSRadio
        },
        props: {
            id: Number,
            userId: [Number, String],
            visible: {
                type: Boolean,
                default: false
            },
            cardType: {
                type: Number,
                default: 1, // 1:用户卡  2：员工卡
            }
        },
        data() {
            return {
                entranceRestrict: false,
                stepCount: 0,
                deposit: 0, // 押金金额
                confirmText: '下一步',
                deviceGroupList: [],
                deviceGroupId: 0,
                cardData: null,
                isSendingCard: false, // 是否发卡中
                panelGroupPanelList: [],
                checked: false,
                deviceIds:[],
                panelName:[]
            }
        },
        computed: {
            writeTip() {
                const tips = {
                    1: `请确保NFC读写设备已连接电脑，再将IC卡放在NFC读写设备上，点击写卡，开始写卡，写卡成功后，可将此卡发给${this.cardType === 1 ? '用户' : '物业员工'}使用。`,
                    2: `写卡成功，${this.cardType === 1 ? `收取门卡押金<span style="color: #00B694">${this.deposit}元</span>后，将此卡发给用户` : '可将此卡发给物业员工'/*'选择此卡使用范围即可发卡'*/}。`,
                };
                return tips[this.stepCount]
            }
        },
        watch: {
            visible(val) {
                if (val) {
                    this.deviceGroupId = 0;
                    this.stepCount = 0;
                    this.confirmText = '下一步';
                    this.cardData = null;
                }
            }
        },
        mounted() {
            this.queryDeviceAll();
            this.loadDeviceGroup(1);
        },
        methods: {

            closeFrom(){
                this.$vc.emit('threePanelSelectFrom',{})
            },

            panelChecked(index){
                this.$set(
                    this.panelGroupPanelList[index],
                    "checked",
                    !this.panelGroupPanelList[index].checked
                );
                if (this.panelGroupPanelList[index].checked) {
                    this.deviceIds.push(
                        this.panelGroupPanelList[index].id
                    );
                    this.panelName.push(this.panelGroupPanelList[index].deviceNo);
                    //this.resolvingPowerId.push(this.panelGroupPanelList[index].resolution);
                } else {
                    let deviceIdIndex = this.deviceIds.indexOf(
                        this.panelGroupPanelList[index].id
                    );
                    this.deviceIds.splice(deviceIdIndex, 1);
                    this.panelName.splice(deviceIdIndex, 1);
                   // this.resolvingPowerId.splice(deviceIdIndex, 1);
                }
            },
            selectAll() {
                if (!this.checked) {
                    this.deviceIds = [];
                    this.panelName = [];
                    this.panelGroupPanelList.forEach((item) => {
                        this.$set(item, "checked", true);
                        this.panelName.push(item.deviceNo);
                        this.deviceIds.push(item.id);
                        // this.resolvingPowerId.push(item.resolution);
                    });
                    this.checked = true;
                } else {
                    this.panelGroupPanelList.forEach((item) => {
                        this.$set(item, "checked", false);
                        this.panelName = [];
                        this.deviceIds = [];
                        // this.resolvingPowerId = [];
                    });
                    this.checked = false;
                }
                console.log('this.deviceIds');
                console.log(this.deviceIds);
            },
            queryDeviceAll() {
                this.$fly.get(`${queryDeviceAllUrl}?regionCode=${this.$vc.getCurrentRegion().code}`).then(res => {
                    if (res.code != 0) {
                        return
                    }
                    res.data = res.data.map(item => {
                        return {
                            ...item,
                            checked: false
                        }
                    })
                    this.panelGroupPanelList = res.data;
                })
            },
            /**
             * 发用户门禁卡
             * @param {Object} cardData 卡片数据
             * @param {String} cardUid 卡号
             * */
            sendAccessCard(cardData, cardUid) {
                const {id, userId, deposit} = this;
                return this.$fly.post(userSendCardUrl, {
                    regionCode: this.$vc.getCurrentRegion().code,
                    id,
                    userId,
                    deposit,
                    cardData,
                    cardUid,
                    copy:this.entranceRestrict?1:2,
                    deviceIds:this.deviceIds
                })
                    .then(res => res.code === 0)
                    .catch(() => false)
            },
            /**
             * 发员工门禁卡
             * @param {Object} cardData 卡片数据
             * @param {String} cardUid 卡号
             * */
            sendStaffAccessCard(cardData, cardUid) {
                const {id} = this;
                /*if (deviceGroupId === 0) {
                  this.$vc.message('选择面板组');
                  return ;
                }*/
                return this.$fly.post(staffSendCardUrl, {
                    // deviceGroupId,
                    cardData,
                    id,
                    cardUid,
                    copy:this.entranceRestrict?1:2,
                    deviceIds:this.deviceIds
                })
                    .then(res => res.code === 0)
                    .catch(() => false)
            },
            // 创建用户写卡数据
            userWriteCard() {
                const {id, userId} = this;
                return this.$fly.post(createAccessCardDataUrl, {
                    id,
                    regionCode: this.$vc.getCurrentRegion().code,
                    userId,
                })
                    .then(res => {
                        if (res.code !== 0) {
                            return [];
                        }
                        return res.data.split('-');
                    })
            },
            // 员工写卡
            staffWriteCard() {
                const {id} = this;
                return this.$fly.get(createAccessStaffCardDataUrl, {
                    id,
                })
                    .then(res => {
                        if (res.code !== 0) {
                            return;
                        }
                        return [res.data];
                    })
            },
            // 刷新面板组
            refreshDeviceGroup() {

            },
            /**
             * 加载面板组
             * @param {Number} pageNo 页数
             * */
            loadDeviceGroup(pageNo) {
                this.$fly.post(queryGroup, {
                    regionCode: this.$vc.getCurrentRegion().code,
                    keyword: '',
                    pageSize: 10,
                    pageNo
                })
                    .then(res => {
                        this.deviceGroupList.splice((pageNo - 1) * 10, this.deviceGroupList.length - (pageNo - 1) * 10, ...(res.data.datas || []));
                    })
            },
            /**
             * 选中面板组
             * @param {Number} id 面板组id
             * */
            pickDeviceGroup(id) {
                this.deviceGroupId = id;
            },
            async writeCard() {
                const writeHandler = {
                        1: this.userWriteCard,
                        2: this.staffWriteCard,
                    },
                    sendHandler = {
                        1: this.sendAccessCard,
                        2: this.sendStaffAccessCard,
                    };
                if (this.isSendingCard) {
                    this.$vc.message(`${this.stepCount === 1 ? '写' : '发'}卡中...`);
                    return;
                }
                const card = ReaderFunction.createNew(),
                    CARD_KEY = 'FFFFFFFFFFFF',
                    CARD_BLOCK = 1;

                switch (this.stepCount) {
                    case 0:
                        this.confirmText = '写卡';
                        this.stepCount++;
                        break;
                    case 1:
                        const writeReturnData = await writeHandler[this.cardType]();
                        this.cardData = writeReturnData[0];
                        this.deposit = writeReturnData[1] || 0;
                        this.isSendingCard = true;
                        // 卡片写入操作
                        card.TyA_CS_Write2(0, CARD_BLOCK, CARD_KEY, this.cardData, res => {
                            console.log('写卡');
                            if (res.retval !== 0) {
                                this.$vc.toast('写卡失败，请重新操作写卡');
                                this.isSendingCard = false;
                                card.Sys_SetBuzzer(card.device_id, 1000, null);
                                card.Sys_SetLight(card.device_id, CARD_DEVICE_COLOR.RED, null)
                                return;
                            }
                            card.Sys_SetLight(card.device_id, CARD_DEVICE_COLOR.GREEN, null)
                            card.Sys_SetBuzzer(card.device_id, 100, null);
                            setTimeout(() => {
                                card.Sys_SetLight(card.device_id, CARD_DEVICE_COLOR.RED, null)
                                this.stepCount++;
                                this.isSendingCard = false;
                            }, 2000)
                            this.confirmText = '发卡';
                        })
                        break;
                    case 2:
                        console.log('执行发卡');
                        card.TyA_GetUID(card.device_id, async readRes => {
                            this.isSendingCard = true;
                            const sendRes = await sendHandler[this.cardType](this.cardData, readRes.data.snr);
                            this.isSendingCard = false;
                            if (sendRes) {
                                this.$emit('refresh');
                                this.onClose();
                                this.$vc.toast('发卡成功');
                            }
                        })
                        break;
                }
            },
            onClose() {
                this.panelGroupPanelList.forEach((item) => {
                    this.$set(item, "checked", false);
                    this.panelName = [];
                    this.deviceIds = [];
                    // this.resolvingPowerId = [];
                });
                this.checked = false;
                this.$emit("onClose");
            }
        }
    }
</script>

<style scoped lang="stylus">
    .access-card-dialog-content
        padding 30px

        .step-wrap
            display flex
            flex-flow row nowrap
            justify-content center
            align-items center
            margin-bottom 40px

            div
                border-radius 50%
                width 70px
                font-size 30px
                height 70px
                line-height 50px
                text-align center
                box-sizing border-box
                box-shadow inset 0 0 4px rgba(32, 19, 19, 0.1)
                border 10px solid #eee
                color #ccc

                &.active
                    color var(--pc-theme-color, rgba(0, 182, 148, 1))

                &.finished
                    background var(--pc-theme-color, rgba(0, 182, 148, 1))
                    color #fff

            .step-bar
                width 160px
                height 10px
                line-height 10px
                border-radius 5px
                background #eee
                position relative
                border 0
                margin 0 10px

                &::before
                    position absolute
                    width var(--bar-width, 50%)
                    left 0
                    top 0
                    border-radius 5px
                    height 10px
                    content " "
                    background var(--pc-theme-color, rgba(0, 182, 148, 1))

        .write-tip
            font-size 24px

        .form-item
            font-size 24px
            margin-top 30px

            &-label
                width 144px
                margin-right 40px

            &-content
                display inline-block
                vertical-align middle


</style>
